<script lang="ts">
	export let title: string;
	export let className = "";
</script>

<p class="{className} text-xl font-semibold leading-[1.13] sm:text-xl md:text-2xl xl:text-4xl">
	{#each title.split("\n") as line}
		{line}
		<br />
	{/each}
</p>
